import React from 'react'
import { logoType, getSlug, replaceStr } from "../../../helper"
import IntroListenerSection from "./layout/IntroListenerSection"
import MainListenerSection from "./layout/MainListenerSection"
import RelatedListenerSection from "./layout/RelatedListenerSection"
import { LISTENER_SHORTCODE } from "../../../constants"

const ListenerOverview = ({ tabs, mainElements, relatedAlternative, wpCptListener, listenerName }) => {
    const listenerSlug = `/${getSlug(wpCptListener.uri)}/${mainElements.slug}`
    const introHeadline = [
        {
            headlineValue: replaceStr(
                LISTENER_SHORTCODE,
                listenerName,
                tabs.overviewH1
            ),
            headlineType: 'H1'
        },
        {
            headlineValue:
                replaceStr(
                    LISTENER_SHORTCODE,
                    listenerName,
                    tabs.overviewH2
                ),
            headlineType: 'H2'
        }
    ]

    const introBodyText = {
        headlines: [
            {
                headlineValue:
                    replaceStr(
                        LISTENER_SHORTCODE,
                        [mainElements.listenerNamePlural, mainElements.listenerNameSingular],
                        tabs.overviewH3
                    ),
                headlineType: 'H3'
            }
        ],
        body: [{
            bodyCopy: replaceStr(
                LISTENER_SHORTCODE,
                listenerName,
                tabs.overviewIntro
            )
        }]
    }

    const listenerTabs = {
        listSlug: {
            overviewSlug: listenerSlug,
            datalayersamplesSlug: `${listenerSlug}/${tabs.datalayersamplesSlug}`,
            trackingintegrationsSlug: `${listenerSlug}/${tabs.trackingintegrationsSlug}`,
            usecasesSlug: `${listenerSlug}/${tabs.usecasesSlug}`,
            helpSlug: `${listenerSlug}/${tabs.helpSlug}`,
        },
        hideTabs: mainElements.hideTabs,
        customPostType: 'listener'
    }

    const creatAccoutContent = {
        headline: mainElements.createAccountLiveStatus.createAccountHeadline ?
            mainElements.createAccountLiveStatus.createAccountHeadline :
            'Get started for free',
        subText: mainElements.createAccountLiveStatus.createAccountSubHeadline ?
            mainElements.createAccountLiveStatus.createAccountSubHeadline :
            'configure your measurement in minutes'
    }

    const creatAccoutComingSoonContent = {
        headline: mainElements.createAccountComingSoonStatus.createAccountHeadline ?
            mainElements.createAccountComingSoonStatus.createAccountHeadline :
            'Create a Trial Account',
        subText: mainElements.createAccountComingSoonStatus.createAccountSubHeadline ?
            mainElements.createAccountComingSoonStatus.createAccountSubHeadline :
            'configure your measurement in minutes'
    }

    const relatedListener = {
        titleH3: relatedAlternative.relatedH3,
        description: replaceStr(
            LISTENER_SHORTCODE,
            listenerName,
            relatedAlternative.relatedDescription
        ),
        relatedListeners: relatedAlternative.relatedListeners,
        showRelated: relatedAlternative.showRelated
    }

    const alternatives = {
        titleH3: `Alternatives to ${mainElements.listenerNameSingular}`,
        description: replaceStr(
            LISTENER_SHORTCODE,
            listenerName,
            relatedAlternative.overrideAlternativeDescription ? relatedAlternative.customAlternativeDescription : relatedAlternative.relatedDescription
        ),
        relatedListeners: relatedAlternative.alternativeListeners,
        showAlternatives: relatedAlternative.showAlternatives
    }

    const primaryLogo = logoType(
        mainElements.logoFileSvg,
        mainElements.logoFileImage,
        mainElements.useImageInsteadOfSvg,
    )


    return (
        <>
            <IntroListenerSection
                headlines={introHeadline}
                primaryLogo={primaryLogo}
                status={mainElements.status}
                creatAccoutContent={creatAccoutContent}
                comingSoonMessage={mainElements.comingSoonMessage}
                nameOfEmailList={mainElements.nameOfEmailList}
            />
            <MainListenerSection
                tabs={listenerTabs}
                status={mainElements.status}
                creatAccoutContent={creatAccoutContent}
                comingSoonMessage={mainElements.comingSoonMessage}
                introBodyText={introBodyText}
                rows={tabs.overviewRows}
                listenerName={listenerName}
                creatAccoutComingSoonContent={creatAccoutComingSoonContent}
                nameOfEmailList={mainElements.nameOfEmailList}
            />
            <RelatedListenerSection
                relatedListener={relatedListener}
                alternatives={alternatives}
                descriptionAboveSearch={mainElements.descriptionAboveSearch}
                instructionsAboveSearch={mainElements.instructionsAboveSearch}
                listeneNamerCurrent={mainElements.listenerNamePlural}
            />
        </>
    )
}

export default ListenerOverview
